import React, { useState } from "react";
import styles from "./style.module.scss";
import Faq from "../../atoms/Faq";
import Goal from "../goal";

const FaqSection = ({moduleData}) => {
  const [faqs, setfaqs] = useState([
    {
      question: "Why to choose Learnio?",
      answer: "We want to ensure you are employable upon graduation, so we offer mentored online learning and career counselling. ",
      open: true,
    },
    {
      question: "What is mentored learning?",
      answer: "Mentored learning is a hybrid model of online learning. We allow you to take our courses at your own pace while providing mentorship from our course instructors and experts to ensure any assistance you need to succeed and graduate. Our mentors also offer career counselling and development to help you land a good job! ",
      open: false,
    },
    {
      question: "Where is Learnio Academy located?",
      answer: "We are an online learning platform with headquarters in Denmark; however, you can learn from anywhere in the world at your own pace. ",
      open: false,
    },
    {
      question: "What kind of support do you provide?",
      answer: "We provide support through multiple channels -\n" +
          "1. Discord Community - You can join our Discord Community (https://discord.gg/G7hT3t8) and get support from our experienced mentors and students anytime. \n" +
          "2. Live Chat Support - You can use our website’s live chat feature to communicate with a representative who can redirect you to the appropriate department.\n" +
          "3. Email Support - You can write to us at academy@learnio.eu, and we will get back to you as soon as we can.\n" +
          "4. Weekly Webinars - Our experienced mentor will arrange weekly webinars so that you can talk to them and get help from them directly\n",
      open: false,
    },
    {
      question: "How can I find work?",
      answer:
        "We suggest you join our community for information on online freelancing jobs and how best to position yourself in the digital landscape. We have a complete course on freelancing where we teach you how you can set up your profiles and win jobs from freelancing marketplaces like Upwork, Fiverr, and People Per Hour. For careers with Learnio, please visit our career page (https://www.coderstrust.com/career).",
      open: false,
    },
    {
      question: "How much does it cost to enroll?",
      answer:
        "All courses are available through a monthly subscription. Learn about our pricing options.",
      open: false,
    },
    {
      question: "How many courses are there?",
      answer:
          "We currently have over 35 courses live however we are constantly releasing new ones! We also want to provide you with the courses you find most useful. So if you are looking for a course but don’t see it yet, write to us at academy@learnio.eu, and we’ll notify you when your course is live.",
      open: false,
    },
    {
      question: "How long can I access the courses?",
      answer:
          "Free courses:\n" +
          "You get lifetime access.\n" +
          "\n" +
          "Paid Courses:\n" +
          "You get full access to all courses available when you subscribe to our platform.",
      open: false,
    },
    {
      question: "How long do the courses take to complete?",
      answer:
          "It depends on the course and how much time you’re able to dedicate to learning. You can learn at your own pace with our courses, and you have lifetime access to course material. On average, it takes a student 2-3 months to complete an entire course and two weeks to complete a crash course.",
      open: false,
    },
    {
      question: "Do I need to have any prerequisites for taking the courses?",
      answer:
          "You need to complete your education up to high school and know what you will learn for introductory courses. In addition, you need to understand the specific course at the primary level for advanced courses.",
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        }
        // If you want to close other FAQs when you click one of them just uncomment the part below
        // else {
        //     faq.open = false;
        // }

        return faq;
      })
    );
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <h3>{moduleData.title}</h3>
          <h2 dangerouslySetInnerHTML={{ __html: moduleData.description}} />
          {faqs.map((faq, i) => (
              <Faq index={i} faq={faq} toggleFAQ={toggleFAQ} />
          ))}
        </div>
      </div>
      <Goal extraClass="reverse" button/>
    </>
  );
};

export default FaqSection;
