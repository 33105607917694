import React from "react";
import styles from "./style.module.scss";
// import {faPlus} from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AiOutlinePlusCircle } from "react-icons/ai";

const Faq = ({ faq, index, toggleFAQ }) => {
  return (
    <div
      className={`${styles.row} ${faq.open && styles.active}`}
      key={index}
      onClick={() => toggleFAQ(index)}
      onKeyDown={() => toggleFAQ(index)}
    >
      <div className={styles.title}>
        <h3>{faq.question}</h3>
        <div className={styles.circle}>
          <AiOutlinePlusCircle className={styles.plusCircle} />
        </div>
      </div>
      <div className={styles.description}>
        <p>{faq.answer}</p>
      </div>
      {index <= 4 && <hr />}
    </div>
  );
};

export default Faq;
