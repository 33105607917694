import React from 'react'
import Banner from "../components/molecules/banner";
import Footer from '../components/molecules/footer';
import SucessStory from "../assets/images/success.png";
import Header from "../components/molecules/header";
import FaqSection from '../components/molecules/faqSection';
import Head from "../components/organisms/head";

const Faq = () => {
  const moduleData = {
    title: "Frequently Asked <br/><span>Questions</span>",
    info: "For any information that you might need, we provide you with a list of Frequently asked questions from our students",
    image: SucessStory,
  };
  const data ={
    title:'FAQ',
    description:'For any information, you might need, we provide you with a list of Frequently asked questions from our students.',
    image:'',
    keywords:''
}
  const moduleDataFaq = {
      title:"FAQ",
      description:"In case you missed anything"
  }
  return (
    <>
      <Head data={data} url={'faq/'}/>
      <Header />
      <Banner moduleData={moduleData} template="apply" />
      <FaqSection moduleData={moduleDataFaq} />
      <Footer />
    </>
  );
};

export default Faq